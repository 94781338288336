export enum AvaErrorCode {
  // default
  DEFAULT_ERROR = 'DEFAULT_ERROR',

  // users
  USER_NOT_FOUND_WITH_ID = 'USER_NOT_FOUND_WITH_ID',
  USER_NOT_FOUND_WITH_EMAIL = 'USER_NOT_FOUND_WITH_EMAIL',
  USER_GROUP_MANDATORY = 'USER_GROUP_MANDATORY',
  USER_MAIL_NOT_UNIQUE = 'USER_MAIL_NOT_UNIQUE',
  USER_MAIL_EMPTY = 'USER_MAIL_EMPTY',

  // customers
  CUSTOMER_ADMIN_MAIL_MANDATORY = 'CUSTOMER_ADMIN_MAIL_MANDATORY',
  CUSTOMER_NOT_FOUND_WITH_ID = 'CUSTOMER_NOT_FOUND_WITH_ID',
  CUSTOMER_DOMAIN_NOT_VALID = 'CUSTOMER_DOMAIN_NOT_VALID',

  // groups
  GROUP_PARENT_ID_MANDATORY = 'GROUP_PARENT_ID_MANDATORY',
  GROUP_NOT_FOUND_WITH_ID = 'GROUP_NOT_FOUND_WITH_ID',
  GROUP_DELETE_ERROR_WITH_SUB_GROUPS = 'GROUP_DELETE_ERROR_WITH_SUB_GROUPS',
  GROUP_DELETE_ERROR_WITH_USERS = 'GROUP_DELETE_ERROR_WITH_USERS',
  GROUP_DELETE_ERROR_WITH_RPOINTS = 'GROUP_DELETE_ERROR_WITH_RPOINTS',
  GROUP_NAME_NOT_UNIQUE = 'GROUP_NAME_NOT_UNIQUE',
  GROUP_DELETE_ERROR_WITH_CUSTOMER = 'GROUP_DELETE_ERROR_WITH_CUSTOMER',

  // assistants
  ASSISTANT_LOGIN_NOT_UNIQUE = 'ASSISTANT_LOGIN_NOT_UNIQUE',
  ASSISTANT_EMAIL_NOT_UNIQUE = 'ASSISTANT_EMAIL_NOT_UNIQUE',

  // notification
  NOTIFICATION_NO_CONTACT_FOUND = 'NOTIFICATION_NO_CONTACT_FOUND',
  NOTIFICATION_NO_EMAIL_FOUND = 'NOTIFICATION_NO_EMAIL_FOUND',
  NOTIFICATION_NO_PHONE_NUMBER_FOUND = 'NOTIFICATION_NO_PHONE_NUMBER_FOUND',
  NOTIFICATION_PHONE_NUMBER_INVALID = 'NOTIFICATION_PHONE_NUMBER_INVALID',

  INVALID_PASSWORD = 'INVALID_PASSWORD',
}

// default
export interface DefaultError {
  code: AvaErrorCode.DEFAULT_ERROR;
}

// users
interface UserNotFoundWithId {
  code: AvaErrorCode.USER_NOT_FOUND_WITH_ID;
}

export interface UserNotFoundWithEmail {
  code: AvaErrorCode.USER_NOT_FOUND_WITH_EMAIL;
  email: string;
}

interface UserGroupMandatory {
  code: AvaErrorCode.USER_GROUP_MANDATORY;
}

export interface UserMailNotUnique {
  code: AvaErrorCode.USER_MAIL_NOT_UNIQUE;
}

export interface UserMailEmpty {
  code: AvaErrorCode.USER_MAIL_EMPTY;
}

// customers
interface CustomerAdminMailMandatory {
  code: AvaErrorCode.CUSTOMER_ADMIN_MAIL_MANDATORY;
}

interface CustomerNotFoundWithId {
  code: AvaErrorCode.CUSTOMER_NOT_FOUND_WITH_ID;
}

interface CustomerDomainNotValid {
  code: AvaErrorCode.CUSTOMER_DOMAIN_NOT_VALID;
}

// groups
interface GroupParentIdMandatory {
  code: AvaErrorCode.GROUP_PARENT_ID_MANDATORY;
}

interface GroupNotFoundWithId {
  code: AvaErrorCode.GROUP_NOT_FOUND_WITH_ID;
}

interface GroupDeleteErrorWithSubGroups {
  code: AvaErrorCode.GROUP_DELETE_ERROR_WITH_SUB_GROUPS;
}

interface GroupDeleteErrorWithUsers {
  code: AvaErrorCode.GROUP_DELETE_ERROR_WITH_USERS;
}

interface GroupDeleteErrorWithRPoints {
  code: AvaErrorCode.GROUP_DELETE_ERROR_WITH_RPOINTS;
}

export interface GroupNameNotUnique {
  code: AvaErrorCode.GROUP_NAME_NOT_UNIQUE;
}

export interface GroupDeleteErrorWithCustomer {
  code: AvaErrorCode.GROUP_DELETE_ERROR_WITH_CUSTOMER;
}

// assistant
export interface AssistantLoginNotUnique {
  code: AvaErrorCode.ASSISTANT_LOGIN_NOT_UNIQUE;
}

export interface AssistantEmailNotUnique {
  code: AvaErrorCode.ASSISTANT_EMAIL_NOT_UNIQUE;
}

export interface NotificationNoContactFound {
  code: AvaErrorCode.NOTIFICATION_NO_CONTACT_FOUND;
}

export interface NotificationNoEmailFound {
  code: AvaErrorCode.NOTIFICATION_NO_EMAIL_FOUND;
}

export interface NotificationNoPhoneNumberFound {
  code: AvaErrorCode.NOTIFICATION_NO_PHONE_NUMBER_FOUND;
}

export interface NotificationPhoneNumberInvalid {
  code: AvaErrorCode.NOTIFICATION_PHONE_NUMBER_INVALID;
}

export interface InvalidPassword {
  code: AvaErrorCode.INVALID_PASSWORD;
}

export declare type AvaErrorPayload =
  | DefaultError
  | UserNotFoundWithId
  | UserNotFoundWithEmail
  | UserGroupMandatory
  | UserMailNotUnique
  | UserMailEmpty
  | CustomerAdminMailMandatory
  | CustomerNotFoundWithId
  | CustomerDomainNotValid
  | GroupParentIdMandatory
  | GroupNotFoundWithId
  | GroupDeleteErrorWithSubGroups
  | GroupDeleteErrorWithUsers
  | GroupDeleteErrorWithRPoints
  | GroupNameNotUnique
  | GroupDeleteErrorWithCustomer
  | AssistantLoginNotUnique
  | AssistantEmailNotUnique
  | NotificationNoContactFound
  | NotificationNoEmailFound
  | NotificationNoPhoneNumberFound
  | NotificationPhoneNumberInvalid
  | InvalidPassword;
